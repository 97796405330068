import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ReactNode } from "react";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
};

/**
 * Modal component that uses headlessui's Dialog and Transition components.
 */
export function Modal({ isOpen, onClose, children, className }: ModalProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="z-modal relative">
        <div className="z-modal fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center backdrop-blur-sm md:p-4">
            <TransitionChild
              as={DialogPanel}
              enter="duration-300 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="duration-200 ease-in"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
              className={classNames(
                "relative max-w-xl rounded shadow-xl transition-all sm:px-6 sm:pb-6 md:my-8",
                className,
              )}
            >
              {children}
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
